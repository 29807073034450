
@keyframes fadeIn {
    from {
      opacity: 0;
      transform: scale(0.5);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }
  
@keyframes fadeOut {
    from {
        opacity: 1;
        transform: scale(1);
    }
    to {
        opacity: 0;
        transform: scale(0.5);
    }
}

.contenitoreInfobox{
    pointer-events: auto;
    animation: fadeIn 0.3s ease-in-out; /* Applica l'animazione fadeIn */
    /*transition: transform 0.3s ease;  Aggiungi una transizione fluida */
    /*border: 1px solid yellow;*/
}

.App {
    text-align: center;
    position: absolute;
    align-items: center;
    z-index: 1000;
    /*border: 1px solid white;*/
    min-height: 50px;
  }
  
  .item {
    flex: 0 0 350px; 
    /*transition: opacity 0.3s ease-in-out; */
  }
  
  .item.active {
    opacity: 1;
  }
  

  .menuScorrimento{
    /*border: 1px solid white;*/
    z-index: 1000;
    position: absolute;
    bottom: 0px;
    margin-bottom: 20px;
    pointer-events: none;
    /*border: 1px solid white;*/
    min-height: 50px;
    pointer-events: auto;
}


.FlyInfoPanelContainer{
    /*border: 1px solid white;*/
    z-index: 1000;
    position: absolute;
    bottom: 0px;
    margin-bottom: 20px;
    pointer-events: none;
    /*border: 1px solid white;*/
    min-height: 50px;
}

.FlyInfoPanelContainerWindy{
    /*border: 1px solid white;*/
    z-index: 1000;
    position: absolute;
    bottom: 50px !important;
    margin-bottom: 20px;
    pointer-events: none;
    /*border: 1px solid white;*/
    min-height: 50px;
    position: absolute !important;
    z-index: 10000000000000000000 !important;
    display: none !important;
    width: calc(100vw - 180px) !important;
}

.FlyInfoPanelContainer2{
    /*border: 1px solid white;*/
    z-index: 1000;
    position: absolute;
    bottom: 0px;
    min-height: 50px;
    width: 90vw;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.FlyInfoPanelContainer2Windy{
    /*border: 1px solid white;*/
    z-index: 1000;
    position: absolute;
    bottom: 0px;
    min-height: 50px;
    width: 90vw;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.menu {
    display: flex;
    min-height: 50px;
    /*border: 1px solid white;*/
    width: 100%;
    z-index: 1000;
}

.buttonContainer{
    height: 65px;
    width: 65px;
    /*border: 1px solid white;*/
    z-index: 1000;
    display: flex;
    flex-direction: column;
    align-items: center; /* Allinea i bottoni centralmente sull'asse orizzontale */
    margin-top: auto;
    margin-bottom: 50px;
}

.arrowButtons{
    height: 55px;
    width: 55px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 25px;
    border-color: 1px solid black;
    transition: opacity 0.7s;
    opacity: 0.6; 
  }

  .arrowIcons {
    background-size: cover;
    font-size: 40px;
    display: inline-block;
    cursor: pointer;
    pointer-events: auto;
  }

@media (min-width: 2399px) {
    .items-container {
        width: calc(95vw - 130px);
        overflow: hidden;
        position: relative;
        min-height: 50px;
        /*border: 1px solid white;*/
    }
}
  
@media (max-width: 2399px) {
    .items-container {
        width: calc(90vw - 130px);
        overflow: hidden;
        position: relative;
        min-height: 50px;
        /*border: 1px solid white;*/
    }
}

  .items-wrapper {
    display: flex;
    /*transition: transform 0.3s ease-in-out; */
    min-height: 50px;
    transition: transform 0.8s ease;
  }

  .arrowButtons:hover {
    opacity: 1; 
  }

.FlyInfoPanelContainerResp{
    z-index: 1000;
    position: absolute;
    bottom: 0px;
    margin-bottom: 20px;
    pointer-events: none;
    /*border: 1px solid white;*/
    min-height: 50px;
}

.FlyInfoPanelContainer2Resp{
    /*border: 1px solid white;*/
    z-index: 1000;
    position: absolute;
    bottom: 0px;
    min-height: 50px;
    width: 95vw;
    display: flex;
    /*flex-direction: column;*/
    justify-content: space-between;
}

.menuResp{
    display: flex;
    min-height: 50px;
    /*border: 1px solid white;*/
    width: 100%;
    z-index: 1000;
}

.divCenterResp{
    /*border: 1px solid white;*/
}

.buttonContainerResp{
    height: 45px;
    width: 45px;
    /*border: 1px solid white;*/
    z-index: 1000;
    display: flex;
    flex-direction: column;
    align-items: center; /* Allinea i bottoni centralmente sull'asse orizzontale */
    margin-top: auto;
    margin-bottom: 10px;
}

.arrowButtonsResp{
    height: 35px;
    width: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 25px;
    border: 1px solid black;
    background-color: gray;
  }

.items-containerResp {
    width: calc(95vw - 88px);
    overflow: hidden;
    position: relative;
    min-height: 50px;
    /*border: 1px solid white;*/
}

.items-wrapperResp {
    display: flex;
    min-height: 50px;
    transition: transform 0.3s ease-in-out;
  }

.arrowIconsResp {
    background-size: cover;
    font-size: 20px;
    display: inline-block;
    cursor: pointer;
    pointer-events: auto;
}

/* break*/
/*
@media (min-width: 2399px) {

    .items-container {
        width: calc(95vw - 130px);
        overflow: hidden;
        position: relative;
        min-height: 50px;
        border: 1px solid white;
      }

}


@media (max-width: 2398px) {

    .items-container {
        width: calc(90vw - 130px);
        overflow: hidden;
        position: relative;
        min-height: 50px;
        border: 1px solid white;
    }

}
*/

/*
.FlyInfoPanel {    
    z-index: 1000;
    min-height: 50px;
    width: 838px; 
    border: 1px solid white;
    display: flex;
    overflow-x: hidden;
    pointer-events: auto;
}
*/

.FlyInfoPanel {    
    z-index: 1000;
    position: absolute;
    bottom: 0px;
    margin-bottom: 20px;
    pointer-events: none;
    /*border: 2px solid white;*/
    /* float: right; */
    min-height: 50px;
}

.buttonScrollContainer{
    width: 90px;
    min-height: 50px;
    /*border: 1px solid white;*/
}

.buttonScroll{
    background-color: #007bff;
  color: white;
  border: none;
  padding: 5px 10px;
  margin: 5px;
  cursor: pointer;
  border-radius: 8px;
  width: 70px;
  height: 40px;
}

/* Personalizzazione delle barre di scorrimento per il browser WebKit (Chrome, Safari) */
.FlyInfoPanel::-webkit-scrollbar {
    width: 12px;
  }
  
  .FlyInfoPanel::-webkit-scrollbar-track {
    background-color: #ccc;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.5);
    border-radius: 10px;
  }
  
  .FlyInfoPanel::-webkit-scrollbar-thumb {
    background-color: rgba(105, 105, 105, 0.7);
    border-radius: 10px;
  }
  
  .FlyInfoPanel::-webkit-scrollbar-thumb:hover {
    background-color: #999;
  }

.img-logo-mini {
    padding:2px;
    width: 24px;
    height: 24px;
    overflow: hidden;
}

.titoloInfoboxCaso1{
    font-size: 16px;
}

.titoloInfoboxCaso2{
    font-size: 14px;
}

.titoloInfoboxCaso3{
    font-size: 15px;
}

.titoloInfoboxCasoLoading{
    font-size: 18px;
}

/* contenitori informazioni degli infobox */
.InfoBox .information {
    padding: 6px;
    /*border: 1px solid black;*/
}

/* contenitori informazioni degli infobox */
.InfoBox .informationLat {
    padding: 3px;
    margin-left: -35px;
/*border: 1px solid black;*/
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
}

/* contenitori informazioni degli infobox */
.InfoBox .informationLon {
    padding: 3px;
    /*border: 1px solid black;*/
    margin-left: -35px;
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: 20px;
}

/* div che contiene il testo */
.labelInfoBox {
    text-transform: uppercase;
    font-weight: 400;
    font-size: 12px;
}

.btn-active {
    background-color: darkgreen;
    color: white;
}

.btnStatus {
    width: 30px;
    height: 17px;
    font-size: 10px !important;
    padding:1px;
    border:1px solid white;
    color: white;
    display: block;
}

.btnStatus.disable-button-status {
    border:1px solid red;
    background-color: red;
    color: white;
}

.btnAction {
    font-size: 12px !important;
    padding:5px;
    border:1px solid white;
    color: black;
    display: block;
    border-radius: 5px;
    cursor: pointer;
}


.btnAction.toogleTrue {
    border:1px solid red;
    background-color: red;
    color: white;
    font-weight: bold;
}

.videoBoxStreaming {
    border-radius: 10px;
    padding: 3px;
    border: 2px solid white;
    background: rgba(105, 105, 105, 0.7);
    color: white !important;
    width: 330px;
    margin:10px;
    cursor: pointer;
    overflow: hidden;
    -webkit-box-shadow: 10px 10px 44px -32px rgba(0,0,0,0.67);
    -moz-box-shadow: 10px 10px 44px -32px rgba(0,0,0,0.67);
    box-shadow: 10px 10px 44px -32px rgba(0,0,0,0.67);
    -webkit-transition: height 1s;
    -moz-transition: max-height 1s;
    -ms-transition: max-height 1s;
    -o-transition: max-height 1s;
    transition: max-height 1s;
    pointer-events: all;
    transition: width 1s, height 1s;
    -ms-transition: width 1s, height 1s;
    -o-transition: width 1s, height 1s;
    -moz-transition: width 1s, height 1s;
}

.ZoomClass {
    width: 800px !important;
}

.InfoBox {
    max-height: 500px;
    overflow: hidden;
    transition: max-height 0.8s linear; /* Aggiungi la transizione alla max-height */
    border-radius: 10px;
    padding:6px;
    margin:10px;
    border: 2px solid white;
    background: rgba(105, 105, 105, 0.7);
    color: white !important;
    max-width: 500px;
    min-width: 330px;
    min-height: 100px;
    cursor: pointer;
    overflow: hidden;
    -webkit-box-shadow: 10px 10px 44px -32px rgba(0,0,0,0.67);
    -moz-box-shadow: 10px 10px 44px -32px rgba(0,0,0,0.67);
    box-shadow: 10px 10px 44px -32px rgba(0,0,0,0.67);
    -webkit-transition: max-height 0.8s;
    -moz-transition: max-height 0.8s;
    -ms-transition: max-height 0.8s;
    -o-transition: max-height 0.8s;
    /*transition: max-height 1s;*/
    pointer-events: all;
    /*border: 1px solid black;*/
    /*float: right;*/
}

.SEARCHING {
    background-color: lightslategray !important;
    color: black !important;
}

.CONNECTED {
    background-color: green !important;
    color: white !important;
}

.LOST {
    background-color: yellow !important;
    color: black !important;
}

.openImagePopup {
    width: 640px;
}

.FLY {
    border-radius: 5px;
    color: white !important;
    background-color: forestgreen;
}

.divider {
    border:1px solid lightgray;
}

.InfoBox .title {
    border-radius: 5px;
}

.InfoBox .title{
    font-size: 14px;
    font-weight: bold;
    padding: 0px;
    padding-left: 10px;
}

.InfoBox .LND {
    background-color: red;
}

.InfoBox .lost {
    background-color: yellow;
}

.InfoBox-Close {
    /*max-height: 149px;*/
    max-height: 127px;
}

/* contenitore del popup registrazione e foto */
.popup-pc {
    position: relative;
    /*border: 2px solid black;*/
    height: 20px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}  

  .popuptext-pc {
    background-color: white;
    border: 2px solid black;
    color: white;
    text-align: center;
    border-radius: 15px;
    position: absolute;
    width: 27px;
    height: 27px;
  }

  .popuptext2-pc {
    background-color: white;
    border: 2px solid black;
    color: white;
    text-align: center;
    border-radius: 15px;
    
    width: 31px;
    height: 31px;
  }

.popup-pc .popuptext-pc::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -6px;
    margin-top: -2px;
    border-width: 6px;
    border-style: solid;
    border-color: white transparent transparent transparent;
}

.popup-pc .popuptext2-pc::after {
    content: "";
    border-width: 6px;
    border-style: solid;
    border-color: white transparent transparent transparent;
}

.fa-chevron-down.InfoBox-Close {
    -webkit-animation: spin1 2s  linear;
    -moz-animation: spin1 2s  linear;
    -o-animation: spin1 2s  linear;
    -ms-animation: spin1 2s  linear;
    animation: spin1 2s  linear;

    -webkit-transform-origin: 50% 50%;
    -moz-transform-origin: 50% 50%;
    -o-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    width: 128px;
    height: 128px;
}

@-webkit-keyframes spin1 {
    0% { -webkit-transform: rotate(0deg);}
    100% { -webkit-transform: rotate(180deg);}
}
@-moz-keyframes spin1 {
    0% { -moz-transform: rotate(0deg); }
    100% { -moz-transform: rotate(180deg);}
}
@-o-keyframes spin1 {
    0% { -o-transform: rotate(0deg);}
    100% { -o-transform: rotate(180deg);}
}
@-ms-keyframes spin1 {
    0% { -ms-transform: rotate(0deg);}
    100% { -ms-transform: rotate(180deg);}
}

@-keyframes spin1 {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(180deg);}
}

.flytoButton {
    background-color: dodgerblue;
    color:white;
}

.boundsButton {
    background-color: dodgerblue;
    color:white;
}

.disable-icon-telemetria {
    opacity: .7;
}

/* icona Rec */
.iconRec-pc{
    width: 21px;
    height: 21px;
}

/* icona Images */
.iconRec2-pc{
    width: 25px;
    height: 25px;
    border-radius: 13px;
}

/* font pc */
.font-24 {
    font-size: 18px;
    font-weight: 600;
}
/* font cell*/
.font-23 {
    font-size: 8px;
    font-weight: 600;
}

/*  

tag media responsive per gestire la responsiveness del sito 

DIVERSO DAL CSS PER TELEFONO / PERO' TSESSO CODICE

ORA STO INSERENDO IL CSS RESPONSIVE

*/

/* contenitore icona registrazione e foto */

.ContenitorePopupInfoBox {
    /* MARGIN-BOTTOM DA CAMBIARE IN BASE AL DEVICE */
    /*border: 2px solid black;*/
    color: white !important;
    cursor: pointer;
    -webkit-box-shadow: 10px 10px 44px -32px rgba(0,0,0,0.67);
    -moz-box-shadow: 10px 10px 44px -32px rgba(0,0,0,0.67);
    box-shadow: 10px 10px 44px -32px rgba(0,0,0,0.67);
    -webkit-transition: height 1s;
    -moz-transition: max-height 1s;
    -ms-transition: max-height 1s;
    -o-transition: max-height 1s;
    transition: max-height 1s;
    pointer-events: all;
    margin-left: 6px;
    height: 55px;
    transition: height 0.5s ease;
    animation: fadeIn 0.3s ease-in-out; /* Applica l'animazione fadeIn */
    /*height: 200px;*/
}

/* gestione responsive infobox */

.InfoBoxResponsive {
    border-radius: 10px;
    padding:3px;
    overflow: hidden;
    transition: height 0.8s linear; /* Aggiungi la transizione alla height */
    /* MARGIN-BOTTOM DA CAMBIARE IN BASE AL DEVICE */
    border: 2px solid white;
    background: rgba(105, 105, 105, 0.7);
    color: white !important;
    width: 130px !important;
    cursor: pointer;
    overflow: hidden;
    -webkit-box-shadow: 10px 10px 44px -32px rgba(0,0,0,0.67);
    -moz-box-shadow: 10px 10px 44px -32px rgba(0,0,0,0.67);
    box-shadow: 10px 10px 44px -32px rgba(0,0,0,0.67);
    -webkit-transition: height 1s;
    -moz-transition: max-height 1s;
    -ms-transition: max-height 1s;
    -o-transition: max-height 1s;
    transition: max-height 1s;
    pointer-events: all;
    float: right;
    height: 55px;
    transition: height 0.5s ease;
    /*height: 200px;*/
}

/* dimensione infobox APERTO TOTALE */
.infoBoxTotale{
    height: 199px;
    transition: height 0.5s ease;
}

/* contenitore dimensione infobox APERTO TOTALE*/
.infoBoxTotaleCont{
    height: 199px;
    transition: height 0.5s ease;
}

/* dimensione infobox APERTO SOLO FOTO*/
.infoBoxFoto{
    height: 102px;
    transition: height 0.5s ease;
}

/* contenitore dimensione infobox APERTO SOLO FOTO*/
.infoBoxFotoCont{
    height: 102px;
    transition: height 0.5s ease;
}

/* dimensione infobox APERTO SOLO DATI */
.infoBoxDati{
    height: 149px;
    transition: height 0.5s ease;
}

/* contenitore dimensione infobox APERTO SOLO DATI*/
.infoBoxDatiCont{
    height: 149px;
    transition: height 0.5s ease;
}

/* contenitore immagini MediaResponsive */
.infoDiv{
    /*border: 1px solid black;*/
    height: 100%;
    width: 50%;
}

.dimImagemedia{
    height: 100%;
    width: 100%;
}

/* contenitore del popup registrazione e foto */
.popup-cell {
    position: relative;
    /*border: 2px solid white;*/
    height: 20px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}  

/* Testo (contenuto) del popup registrazione e foto */
.popuptext-cell {
    
  }

  .popuptext2-cell {
    
  }

  /* icona Rec */
.iconRec-cell{
    border-radius: 8px;
    width: 14px;
    height: 14px;
}

/* icona Images */
.iconRec2-cell{
    width: 17px;
    height: 17px;
    border-radius: 15px;
}

/* titolo del responsive infobox */
.InfoBoxResponsive .title {
    font-size: 9px;
    font-weight: bold;
    padding: 0px;
    padding-left: 10px;
    margin-bottom: 3px;
    border-radius: 5px;
}

/* informazioni responsive infobox */
.InfoBoxResponsive .information {
    padding-top: 1px;
    padding-bottom: 1px;
}

/* informazioni responsive infobox */
.InfoBoxResponsive .informationLat {
    padding-top: 1px;
    padding-bottom: 1px;
    /*border: 1px solid black;*/
}

/* informazioni responsive infobox */
.InfoBoxResponsive .informationLon {
    padding-top: 1px;
    padding-bottom: 1px;
    /*border: 1px solid black;*/
}

.InfoBoxResponsive .LND {
    background-color: red;
}

.InfoBoxResponsive .LND {
    background-color: red;
}

.InfoBoxResponsive .lost {
    background-color: yellow;
}


/* Contenitore InfoBox Title Responsive */
.divInfoBox{
    width: 110px;
    margin-left: -5px;
}

/* div che contiene il testo, assegnato anche al testo del div */
.divInfoBoxElements1{
    width: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    /*border: 1px solid black;*/
}

/* div che contiene il testo, assegnato anche al testo del div */
.divInfoBoxElements2{
    width: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-left: 15px;
    /*border: 1px solid black;*/
}

/* div che contiene il testo, assegnato anche al testo del div */
.divInfoBoxElements3{
    width: 65px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    /*border: 1px solid black;*/
    margin-left: -7px;
}

/* div che contiene il testo, assegnato anche al testo del div */
.divInfoBoxElements4{
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    /*border: 1px solid black;*/
}

/* responsive grandezza freccia per aprire/chiudere infobox */
.sizeFreccia{ 
    font-size: 15px;
    margin-top: 1px;
}

/* bottone foto*/
.photoButt{
    width: 40px;
    height: 23px;
    margin-left: 2px;
    /*font-size: 9px;*/
}

/* bottone streaming*/
.streamingButt{
    width: 40px;
    height: 23px;
    margin-left: 2px;
    /*font-size: 9px;*/
}

/* bottone follow */
.followButt{
    width: 40px;
    height: 23px;
    margin-left: 2px;
    /*font-size: 9px;*/
}

/*font cell per tasto photo e follow */
.fontPhotoFollow {
    font-size: 9px;
    font-weight: 600;
}

/*font cell per tasto photo e follow */
.fontStreamingFollow {
    font-size: 9px;
    font-weight: 600;
}

/* testo del valore delle label per la parte responsive*/
.valueInfoBox{
    font-size: 8px;
}

/* testo del valore delle label per la parte responsive*/
.valueInfoBox2{
    font-size: 8px;
    margin-bottom: -15px;
}

/* testo delle label per la parte responsive*/
.labelInfoBoxResponsive {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 9px;
}

/* gestione responsive del contenitore di ogni singolo Infobox (vedi App.js per trovarlo)*/

.FlyInfoPanelResponsive {
    z-index: 1000;
    position: absolute;
    bottom: 0px;
    margin-bottom: 10px;
    pointer-events: none;
    /*width: 100%;*/
    /*border: 1px solid black;*/
    left: 0;
    display: flex;
    flex-wrap: wrap-reverse;
}


/* contenitore titolo InfoBoxTitleResponsive e InfoBox responsive */
.divInfoTitle{
    font-size: 8px;
}

/* contenitore del popup registrazione e foto */
.popup-cell {
    position: relative;
    /*border: 2px solid white;*/
    height: 20px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}  

/* effetto su elemento precedente */
.popup-cell .popuptext-cell::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -6px;
    margin-top: -2px;
    border-width: 6px;
    border-style: solid;
    border-color: white transparent transparent transparent;
}


  /* effetto su elemento precedente */
  .popup-cell .popuptext2-cell::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -6px;
    margin-top: -2px;
    border-width: 6px;
    border-style: solid;
    border-color: white transparent transparent transparent;
}

/* contenitore dei 2 bottoni: foto e follow */
.divButton{
    margin-top: 1px;
}

/* contenitore del ConnectionState */
.divInfoSotto{
    margin-top: -8px;
}

.popuptext-pc-new {
    
  }

.popup-pc .popuptext-pc-new::after {
    
}

/* icona Rec */
.iconRec-pc-new{
    border-radius: 20px;
    width: 35px;
    height: 25px;
}

.borderProperty{
    border: 1px solid black;
  }

.videoStreamingContainer{
    width: 314px; 
    height: 145px; 
    /*border: 1px solid black;*/
}

.containerStreaming{
    height: 100%;
    width: 100%;
}

.streamingContainer{
    width: 120px; 
    height: 100px; 
}

.custom-video-player-cell{
    width: 120px;
    height: 90px;
}

.custom-video-player-pc{
    width: 100%;
    height: 100%;
}

.iframeResponsive{
    width: 100%;
    height: 100%;
}

.controls {
    width: 10px !important;
    height: 10px !important; /* of figure's height */
    position: relative !important;
  }  

  .controls button {
    border: none;
    cursor: pointer;
    background: transparent;
    background-size: contain;
    background-repeat: no-repeat;
    width: 5px !important;
    height: 5px !important;
  }
  

.streamingIframe{
    height: 100%;
    width: 100%;
}

#video{
    height: 100%;
    width: 100%;
}

.videoStreamingContainerExpanded{
    width: 815px; 
    height: 600px;
    position: relative;
}

.videoStreamingContainerExpanded iframe {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 999999;
}

.allargoStreaming{
    width: 830px;
}

.map-container{
    z-index: 9999999;
    position: absolute;
}

#video{
    font-size: 2px;
}

/*

.vjs-progress-control{
    display: block !important;
    z-index: 1000000000 !important;
}

.vjs-control{
    display: block !important;
    z-index: 10000000000 !important;
}

.vjs-control-text{
    display: block !important;
    z-index: 10000000000 !important;
}

.vjs-current-time-display{
    display: block !important;
    z-index: 10000000000 !important;
}

.vjs-time-divider{
    display: block !important;
    z-index: 10000000000 !important;
}

.vjs-duration-display{
    display: block !important;
    z-index: 10000000000 !important;
}

.vjs-slider-horizontal{
    display: block !important;
    z-index: 10000000000 !important;
}

.vjs-load-progress{
    display: block !important;
    z-index: 10000000000 !important;
}

.vjs-control-text-loaded-percentage{
    display: block !important;
    z-index: 10000000000 !important;
}

.vjs-mouse-display{
    display: block !important;
    z-index: 10000000000 !important;
}

.vjs-time-tooltip{
    display: block !important;
    z-index: 10000000000 !important;
}

.vjs-slider-bar{
    display: block !important;
    z-index: 10000000000 !important;
}

.vjs-control{
    display: block !important;
    z-index: 10000000000 !important;
}

*/

@media (min-width: 1000px) {

    .containerStreaming{
        width: 100%;
    }

    .messageErrorStreaming{
        font-size: 20px;
    }

    .video-js{
        width: 100%;
        height: 200px;
    }

    .vjs-seek-to-live-control{
        display: flex !important;
        flex-direction: row !important;
        z-index: 10000000000 !important;
    }
    
    .vjs-icon-placeholder{
        display: block !important;
        z-index: 10000000000 !important;
    }
    
    .vjs-control-text{
        display: block !important;
        z-index: 10000000000 !important;
    }
    
    .vjs-seek-to-live-text{
        display: block !important;
        z-index: 10000000000 !important;
    }
    
    .vjs-live-display{
        display: none !important;
    }
    
    .vjs-control-bar{
        display: flex !important;
        flex-direction: row !important;
        z-index: 10000000000 !important;
    }

}


@media (max-width: 999px) {

    .containerStreamingResp{
        width: 100%; 
    }
    

    .messageErrorStreamingResp{
        font-size: 10px;
    }

    .video-js{
        width: 100%; 
        height: 100%; 
    }

    .vjs-control-bar{
        display: flex !important;
        flex-direction: row !important;
        z-index: 10000000000 !important;
    }

    .vjs-seek-to-live-control{

    }

    .vjs-volume-panel{
        display: none !important;
    }
      
    
    .vjs-icon-placeholder{
        
    }
    
    .vjs-control-text{

    }
    
    .vjs-seek-to-live-text{

    }
    
    .vjs-live-display{
        display: none !important;
    }
    
    .vjs-control-bar{
        
    }

}

.inactiveButton {
    opacity: 0.5; /* Opacità ridotta per rendere il bottone leggermente invisibile */
    cursor: not-allowed; /* Cambia il cursore per indicare che il bottone non è cliccabile */
    pointer-events: none; /* Impedisce l'interazione del mouse */
  }
  
  .controlsContainer .playButton[paused] {
    display: none !important;
  }
  