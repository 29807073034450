.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.leaflet-container {
  width:100vw;
  height: 100vh;
}


.icon-header {
  fill: none;
  stroke: red;
  stroke-width: 5px;
  animation: MagicKingdom infinite 5s;
  transition: stroke-width .5s;
}


/*  AGGIUNTE  */

html,
body,
#root {
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.map-wrapper {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

#windy {
  width: 100vw;
  height: 100vh;
  display: none;
  /*position: relative !important;*/
}

.leaflet-container {
  height: 100%;
}

.esa-header{
  opacity: 0.9;
  box-sizing: border-box;
  position: relative;
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 24px;
  background-color: #003247;
  border-bottom-width: 4px;
  border-bottom-style: solid;
  border-color: #00ae9d;
  color: #fff;
  font-size: 20px;
  z-index: 99999;
}

.divIconsRight{
  width: 40%;
  margin-left: auto;
  line-height: 0;
}

.divIconsLeft{
  width: 30%;
  display: flex;
  align-items: center;
  justify-content: center;
}

#mioPulsante{
  width: 180px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  background-color: #3498db;
  color: #fff;
  border: none;
  border-radius: 10px;
  transition: background-color 0.3s ease;
  margin-right: 5%;
  border: 1px solid black;
}

#mioPulsante:hover {
  background-color: #2980b9;
}